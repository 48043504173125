import React from "react"
import Layout from "@components/layout"
import Seo from "@components/seo"

// Import SVG
import notSVG1 from '@images/404-icon.svg';

// 言語用Jsonファイル
import { LangSetting } from "@components/language-setting.js";
import langEn from "@content/locales/en/404.json";
import langJa from "@content/locales/ja/404.json";

import { SeoContents } from "@components/seo-data.js" // Seo情報設定

const NotFoundPage = ( props ) => {

  // 言語設定
  const setLang = LangSetting( props, langEn, langJa ).SetLang;
  const postLang = LangSetting( props, langEn, langJa ).PostLang;

  // 言語別URL設定
  let langUrl;
  (setLang === 'en') ? langUrl = '/' : langUrl = '/' + setLang + '/';
  let signPath1 = langUrl + "register/";

  // Seo情報設定
  const pathName = '404'; // ファイル名
  const title = postLang["page-title"];
  const description = postLang["page-description"];
  const keywords = postLang["page-keywords"];
  const seoTag = SeoContents( pathName, setLang, title, description, keywords, "", "" );

  return (
  <>
    {seoTag}
    <Layout>
      <div className="FV pt-5 pb-8 bg-fixed" langType={setLang}>
        <div className="max-w-xl mx-auto px-6 md:max-w-5xl md:pt-5 md:flex">
          <div className="md:w-[82%] text-center mx-auto h1_filed">
            <h1 className="md:text-2xl font-semibold text-xl">{postLang["404-txt1"]}<br></br>{postLang["404-txt2"]}</h1>
          </div>
        </div>
      </div>
      <div className="max-w-xl mx-auto py-11 md:max-w-5xl px-6 mb-[70px] text-center">

      <div className="mx-auto max-w-[113px] mt-12 mb-5"><img className="lazyload" data-src={notSVG1} alt="" /></div>
        <Seo title="404: Not Found" />
        <h1 className="font-semibold text-[60px] text-[#666666]">404 : Not Found</h1>
      </div>
      <div className="mt-11 py-14 md:py-10"> <a href={signPath1} className="rounded block md:mx-auto md:max-w-xs mx-5 text-center text-white font-bold py-5 px-16 my-2 bg-black hover:bg-black/70rounded block md:mx-auto md:max-w-xs mx-5  text-center text-white font-bold  px-16 my-2 bg-black hover:scale-[1.05] pt-[18px] pb-[20px] duration-300 ">{postLang["open"]}</a> </div>
    </Layout>
  </>
  )
}
export default NotFoundPage


